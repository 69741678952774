.loading {
  display: inline-block;
  position: relative;
  width: 2.5em;
  height: 2.5em;
  span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 2em;
    height: 2em;
    margin: 0.25em;
    border: 0.25em solid $primary;
    border-radius: 50%;
    animation: rwgLoading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $primary transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes rwgLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
