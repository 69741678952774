$green: #a2a569;
$washed-green: #b0b67a;
$light-green: #90a641;
$serif: "Merriweather", serif;

@import "~animate.css/animate.css";
@import "~tachyons-sass/tachyons.scss";
@import "mixins";

$primary: $green !default;
$secondary: $light-green !default;
$accent: $light-green;

@include tachyons-color-gen("primary", $primary);
@include tachyons-color-gen("secondary", $secondary);
@include tachyons-color-gen("accent", $accent);

.post-content {
  img {
    object-fit: cover;
  }

  .wp-block-image {
    margin-left: auto;
    margin-right: auto;
  }

  .wp-block-button__link {
    @extend .bg-animate,
      .pointer,
      .no-underline,
      .br2,
      .pv2,
      .ph4,
      .white,
      .bg-accent,
      .bn,
      .hover-bg-primary,
      .ttu,
      .f5,
      .shrink;
  }
}

@include tachyons-prop(".fw5") {
  font-weight: 500;
}

@include tachyons-prop(".fw6") {
  font-weight: 600;
}

@include tachyons-prop(".object-cover") {
  object-fit: cover;
}

@include tachyons-prop(".object-contain") {
  object-fit: contain;
}

@include tachyons-prop(".shrink") {
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;

  &:hover,
  &:focus {
    transform: scale(0.85);
  }

  &:active {
    transform: scale(0.9);
  }
}

@include tachyons-prop(".gradient-1") {
  background-image: linear-gradient(180deg, #fcfcfc 0%, #e8eefc 100%);
}

@include tachyons-prop(".gradient-2") {
  background-color: transparent;
  background-image: linear-gradient(180deg, #bdbdbd 0%, $primary 100%);
  opacity: 0.73;
  transition: background-image 0.3s, border-radius 0.3s, opacity 0.3s;
}

@include tachyons-prop(".lh-large") {
  line-height: 1.85;
}

@include tachyons-prop(".text-shadow") {
  text-shadow: 0px 3px 6px $near-black;
}

@include tachyons-prop(".o-animate") {
  transition: opacity 0.25s ease-in-out;
}

@include tachyons-prop(".o-20") {
  opacity: 0.2;
}

@include tachyons-prop(".o-0") {
  opacity: 0;
}

@include tachyons-prop(".map-height") {
  height: 30rem;
}

@include tachyons-prop(".map-height-2") {
  height: 20rem;
}

@include tachyons-prop(".map-height-auto") {
  height: auto;
}

@include tachyons-prop(".blur") {
  filter: blur(0.148rem);
}

@import "loading";
@import "logo";

.post-content {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  figure,
  .wp-block-columns,
  ul,
  ol {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin: 0;
    }
  }

  .wp-block-image {
    img {
      display: block;
    }
  }
}

*:focus,
*:active {
  outline: none;
}
